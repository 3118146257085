<template>
  <b-embed
    :type="item.type"
    aspect="16by9"
    :src="item.src"
    v-bind="item.attrs"
  />
</template>

<script>
export default {
  name: 'EmbedVideo',

  props: {
    src: { type: String, required: true },
    type: { type: String, required: true },
    full: { type: Boolean, default: false }
  },

  data () {
    return {
      types: [
        {
          reg: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/i,
          url: 'https://www.youtube.com/embed/$5',
          params: {
            'picture-in-picture': 1,
            accelerometer: 1,
            gyroscope: 1
          }
        },
        {
          reg: /^.*vimeo.com\/(\d+)($|\/|\b)/i,
          url: 'https://player.vimeo.com/video/$1',
          params: {
            title: 1,
            byline: 1,
            portrait: 0
          }
        },
        {
          reg: /^.*(?:\/video|dai.ly)\/([A-Za-z0-9]+)([^#&?]*).*/i,
          url: 'https://www.dailymotion.com/embed/video/$1',
          params: {
            autoplay: 0
          }
        }
      ]
    }
  },

  computed: {
    embed () {
      return this.types.find(type => type.reg.exec(this.src))
    },

    item () {
      const embed = this.embed
      let src = this.src
      const attrs = this.type === 'iframe' ? { allowfullscreen: true } : { controls: true }

      if (this.full && this.type === 'iframe') {
        attrs.width = '100%'
        attrs.height = '100%'
      }

      if (embed) {
        const query = Object.entries(embed.params).map(entry => entry.join('=')).join('&')
        src = this.src.replace(embed.reg, embed.url)
        if (!this.full) {
          src += embed.url.indexOf('?') >= 0 ? '&' : '?' + query
        }
      }

      return { type: this.type, src, attrs }
    }
  }
}
</script>
