import Vue from 'vue'

import api from '@/api'
import {
  DATA_LEVELS,
  ID_VARIANTS,
  VARIANT_IDS,
  RELATIONS,
  reduceQueryIds,
  reduceQueryLevels,
  formatDate
} from '@/store/utils'
import {
  AllNodesOfVariant
} from '@/api/queries'


export default {
  state: {
    debug: localStorage.getItem('debug') === 'true',
    nodes: {},
    history: [],
    optionsVisible: true,
    // IDS
    ids: {
      // depart: undefined,
      // kit: undefined,
      // creation: undefined
    }
  },

  mutations: {
    'SET_ALL_NODES_IDS' (state, [variant, nodes]) {
      Vue.set(state.ids, variant, nodes.map(({ id }) => id))
    },

    'ADD_NODES' (state, [nodes, dataLevel]) {
      function addNode (node, level) {
        const stateNode = node.id in state.nodes ? state.nodes[node.id] : undefined

        if (node.type === 'Creation') {
          node.variant = [{ id: 0 }]
          if (node.files && node.files.length) {
            const creationFile = node.files.shift()
            if (!node.files.length) node.files = null
            const mime = creationFile.filemime
            if (mime === 'application/pdf') {
              node.mediaItem = {
                type: 'iframe',
                src: creationFile.url + '#toolbar=0&navpanes=0&statusbar=0'
              }
            } else if (mime === 'text/html') {
              node.mediaItem = { type: 'iframe', src: creationFile.url }
            } else if (['audio', 'video'].some(type => mime.includes(type))) {
              node.mediaItem = {
                type: mime.includes('audio') ? 'audio' : 'video',
                src: creationFile.url
              }
            } else if (node.piece && node.piece.url) {
              node.mediaItem = { type: 'iframe', src: node.piece.url }
            }
          } else if (node.piece && node.piece.url) {
            node.mediaItem = { type: 'iframe', src: node.piece.url }
          }

          if (node.images && node.images.length) {
            node.image = node.images.shift()
          }
        }

        if (node.date) {
          node.date.start = formatDate(node.date.start)
        }


        if ('creations' in node) {
          if (node.creations) {
            if (!Array.isArray(node.children)) {
              node.children = []
            }
            node.creations.forEach(item => {
              item.variant = [{ id: 0 }]
              node.children.push(item)
            })
          }
          delete node.creations
        }

        if (node.variant === null || Array.isArray(node.variant)) {
          node.variant = node.variant !== null ? ID_VARIANTS[node.variant[0].id] : 'dark'
        }
        if (node.type) {
          node.type = node.type === 'Textref' ? 'ref' : 'prod'
        }

        if (node.tags) {
          node.tags = node.tags.map(tag => {
            return { ...tag, name: tag.name[0].toUpperCase() + tag.name.slice(1) }
          })
        }

        for (const relation of RELATIONS) {
          if (relation in node && node[relation]) {
            node[relation] = node[relation].map(subNode => {
              if (!(subNode.id in state.nodes)) {
                addNode(subNode, -1)
              }
              return state.nodes[subNode.id]
            })
          }
          if (node[relation] === null) {
            node[relation] = []
          }
        }

        if (node.notes) {
          node.notes.forEach(note => {
            if (note.links && note.links.length) {
              note.links = note.links.map(link => {
                if (!(link.id in state.nodes) || state.nodes[link.id].dataLevel < 1) {
                  addNode(link, -1)
                }
                return state.nodes[link.id]
              })
            }
          })
        }

        if (!stateNode || stateNode.dataLevel < level) {
          node.dataLevel = level
        }
        if (stateNode === undefined) {
          Vue.set(state.nodes, node.id, node)
        } else {
          for (const key in node) {
            Vue.set(state.nodes[node.id], key, node[key])
          }
        }
      }

      for (const node of nodes) {
        addNode(node, dataLevel)
      }
    },

    'ADD_HISTORY_ENTRIES' (state, ids) {
      for (const id of ids) {
        if (!state.history.includes(id)) {
          state.history.push(id)
        }
      }
    },

    'UPDATE_OPTIONS_VISIBILITY' (state, visible) {
      state.optionsVisible = visible
    }
  },

  actions: {
    async 'GET_ALL_NODES_IDS' ({ state, commit }, { variant, dataLevel = 'initial' }) {
      if (state.ids[variant] === undefined) {
        const levels = reduceQueryLevels(DATA_LEVELS[dataLevel])
        await api.queryAllNodes(VARIANT_IDS[variant], levels).then(({ nodes }) => {
          commit('SET_ALL_NODES_IDS', [variant, nodes])
          commit('ADD_NODES', [nodes, DATA_LEVELS[dataLevel]])
        })
      }
      return state.ids[variant]
    },

    async 'GET_NODES' ({ state, commit, getters }, { ids, dataLevel = 'partial' }) {
      const { idsToQuery, levelsToQuery } = reduceQueryIds(ids, dataLevel, state.nodes)
      if (idsToQuery) {
        await api.queryNodes(idsToQuery, levelsToQuery).then(data => {
          commit('ADD_NODES', [data.nodes, DATA_LEVELS[dataLevel]])
        })
      }

      return getters.nodes(ids)
    },

    async 'GET_NODE' ({ state, commit, getters }, { id, dataLevel = 'full' }) {
      const lvl = DATA_LEVELS[dataLevel]
      const nodeLvl = id in state.nodes ? state.nodes[id].dataLevel : -1
      if (nodeLvl < lvl) {
        const levelsToQuery = Array(lvl - nodeLvl).fill(nodeLvl + 1).map((v, i) => v + i)
        await api.queryNode(id, levelsToQuery).then(data => {
          commit('ADD_NODES', [[data.node], lvl])
        })
      }

      return getters.node(id)
    }
  },

  getters: {
    debug: state => state.debug,
    allNodes: state => state.nodes,
    // Args getters
    nodes: state => ids => ids.map(id => state.nodes[id]),
    node: state => id => state.nodes[id],
    optionsVisible: state => state.optionsVisible,
    history: state => {
      return state.history.map(id => state.nodes[id]).filter(node => {
        return node.dataLevel > 0
      })
    }
  }
}
