<template>
  <div
    class="node-view-body"
    :class="['node-view-body-' + mode, 'node-view-body-' + type]"
  >
    <slot name="default">
      <node-view-figure
        v-if="mode === 'view' && type === 'prod' && node.image"
        @expand-image="image = $event"
        :node="node"
      />

      <div
        v-if="mode === 'card' && !node.content && !!node.images && !!node.images.length"
        class="node-view-img-wrapper mt-3"
      >
        <img :src="node.images[0].url" :alt="node.images[0].alt">
      </div>

      <div class="node-view-body-wrapper" v-html="node.content" />

      <div class="node-view-gallery" v-if="mode === 'view'">
        <div v-if="node.images" class="mt-5">
          <div class="node-view-img-wrapper mt-3" v-for="img in node.images" :key="img.id">
            <img :src="img.url" :alt="img.alt">
            <button-expand @click="onImageExpandClick(img)" v-b-modal="'modal-image-' + node.id" />
          </div>
        </div>

        <div v-if="node.videos">
          <embed-video
            v-for="video in node.videos" :key="video.url"
            :src="video.url" type="video" class="mt-3"
          />
        </div>

        <ul v-if="node.files">
          <h6>Fichiers:</h6>

          <li v-for="file in node.files" :key="file.fid">
            <b-button variant="dark" :href="file.url">
              {{ file.filename }}
            </b-button>
          </li>
        </ul>
      </div>

      <fullscreen-modal v-if="mode === 'view' && image" :image="image" :id="'modal-image-' + node.id" />

      <template v-if="mode === 'view'">
        <b-popover
          v-for="note in node.notes" :key="`note-${mode}-${node.id}-${note.number}`"
          custom-class="footnote"
          :target="`note-${node.id}-${note.number}`" :container="`node-${mode}-${node.id}`"
          placement="top" :fallback-placement="['bottom', 'right', 'left']"
          :triggers="['focus']"
        >
          <div class="footnote-content" v-html="note.content" />

          <div class="footnote-child-list" v-if="note.links">
            <node-view-title
              v-for="link in note.links" :key="link.id"
              :node="getLinkObj(link)"
              link
              @open-node="onFootnoteLinkClick(link, `note-${node.id}-${note.number}`)"
            >
              <dot-button
                :variant="link.variant"
                class="mr-2"
                active hovered
              >
                {{ $t('variants.' + link.variant) }}
              </dot-button>
            </node-view-title>
          </div>
        </b-popover>
      </template>
    </slot>
  </div>
</template>

<script>
import { getRelation } from '@/store/utils'
import { NodeViewTitle, NodeViewFigure } from '@/components/nodes'


export default {
  name: 'NodeViewBody',

  components: {
    NodeViewTitle,
    NodeViewFigure
  },

  props: {
    node: { type: Object, required: true },
    type: { type: String, required: true },
    mode: { type: String, required: true }
  },

  data () {
    return {
      image: { id: '', url: '', alt: '' }
    }
  },

  methods: {
    getLinkObj (link) {
      if (link.preTitle || link.italTitle) return link
      if (link.type === 'prod' && link.parents && link.parents.length) return link.parents[0]
      return link
    },

    addFootnotes () {
      const links = this.$el.querySelectorAll('a')
      links.forEach((link, i) => {
        const number = parseInt(link.hash.replace('#', ''))
        if (!isNaN(number)) {
          link.classList.add('footnote-link')
          link.id = `note-${this.node.id}-${number}`
          link.innerHTML = link.textContent
          if (link.parentElement.nodeName === 'SUP') {
            link.parentElement.replaceWith(link)
          }
          link.setAttribute('href', 'javascript:;')
        }
      })
    },

    hideFootnotes () {
      const links = this.$el.querySelectorAll('a')
      links.forEach((link, i) => {
        const number = parseInt(link.hash.replace('#', ''))
        if (!isNaN(number)) {
          link.classList.add('footnote-link')
          if (link.parentElement.nodeName === 'SUP') {
            link.parentElement.replaceWith(link)
          }
        }
      })
    },

    mountMedias () {
      const template = document.getElementById('expand-image-tmp')
      this.$el.querySelectorAll('.node-view-body-wrapper img').forEach(img => {
        const image = { id: img.dataset.entityUuid, url: img.src, alt: img.alt }

        const clone = document.importNode(template.content, true)
        const cloneImg = clone.querySelector('img')
        cloneImg.setAttribute('src', image.url)
        cloneImg.setAttribute('alt', image.alt)
        cloneImg.setAttribute('id', image.id)
        clone.querySelector('button').onclick = () => {
          this.onImageExpandClick(image)
        }

        img.parentElement.replaceWith(clone)
      })
    },

    onImageExpandClick (image) {
      this.image = image
      this.$bvModal.show('modal-image-' + this.node.id)
    },

    onFootnoteLinkClick (node, popoverBtnId) {
      this.$root.$emit('bv::hide::popover', popoverBtnId)
      this.$parent.$emit('open-node', getRelation(node))
    }
  },

  created () {
    if (this.mode === 'view' && this.node.notes) {
      // Query partial data for linked nodes in notes
      const ids = this.node.notes.filter(note => (note.links)).reduce((ids, note) => {
        note.links.forEach((link) => {
          ids.push(link.id)
          if (link.parents && link.parents.length) {
            ids.push(link.parents[0].id)
          }
        })
        return ids
      }, [])
      this.$store.dispatch('GET_NODES', { ids, dataLevel: 'initial' })
    }
  },

  mounted () {
    if (this.mode === 'view') {
      this.addFootnotes()
      this.mountMedias()
    } else {
      this.hideFootnotes()
    }
  }
}
</script>

<style lang="scss">
.node-view-body {
  width: 100%;

  img {
    display: block;
    max-width: 100%;
    max-height: 80vh;
  }

  font: {
    family: $font-family-text;
    line-height: inherit;
  }

  blockquote {
    padding-left: 2em;
    margin: 1.5em 0;
    font-size: .9em;

    @include media-breakpoint-up($size-bp) {
      font-size: .85em;
    }
  }

  pre {
    white-space: pre-wrap;
  }

  // Kit atelier
  &-wrapper ul:first-child {
    background-color: $black;
    color: $white;
    font-family: $font-family-base;
    font-size: 1rem;
    list-style: none;
    padding: .75rem;
    margin-top: 1rem;

    li {
      &:not(:last-of-type) {
        margin-bottom: .25rem;
      }

      p {
        margin: 0;
      }
    }

  }

  &-card &-wrapper ul:first-child {
    li {
      flex-basis: 100%;
    }
    strong {
      display: inline;
    }
  }

  @include media-breakpoint-up($size-bp) {
    font-size: 2rem;
  }

  &-view {
    padding: 0 $node-view-spacer-sm-x;
    font-size: 1.25rem;
    word-wrap: break-word;

    @include media-breakpoint-up($size-bp) {
      padding: 0 $node-view-spacer-x;

      &.node-view-body-ref {
        font-size: 2.6rem;
      }

      &.node-view-body-prod {
        font-size: 1.5rem;
      }
    }
  }

  &-card {
    padding: 0 $node-card-spacer-sm-x;
    font-size: 1.1rem;
    word-wrap: anywhere;

    @include media-breakpoint-up($size-bp) {
      font-size: 2rem;
      padding: 0 $node-card-spacer-x;
    }
  }

  &-card &-wrapper {
    @include line-clamp(3, 1.5em);
  }
  &-card#{&}-prod &-wrapper {
    @include line-clamp(4, 1.5em);
  }

  // To avoid weird ul rendering of kits extra infos
  .node-view-kit #{&}-card#{&}-prod &-wrapper {
    display: block;
    text-overflow: unset;
  }

  .footnote-link {
    display: inline-block;
    text-align: center;
    background-color: $black;
    color: $white;
    font-family: $font-family-text;
    font-weight: $font-weight-bold;
    font-size: 0.8em;
    text-decoration: none;
    border-radius: 1em;
    padding: 0 .5em;
    min-width: 1.25em;
    max-height: 1.4em;
    margin: 0 .2em;
  }

  &-card .footnote-link {
    display: none;
  }
}

.footnote {
  background-color: $black !important;
  color: $white;
  font-size: 1.25rem;

  @include media-breakpoint-up($size-bp) {
    font-size: 1.5rem;
  }

  h6 {
    font-size: inherit;
  }

  &-content,
  &-child-list {
    > :last-child {
      margin-bottom: 0;
    }
  }

  &-child-list {
    margin-top: .5rem;
  }
}
</style>
